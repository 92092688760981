import { autoinject } from 'aurelia-framework';
import { NavigationInstruction, RouteConfig, Router } from 'aurelia-router';
import { ThreatsHistory } from 'components/charts/business/threats-history/threats-history';
import { Spinner } from 'components/spinner/spinner';
import {
    ActionTarget,
    DetectionTypes,
    LatestVesselRisk, SiteStats,
    StatsApiClient,
    ThreatsApiClient,
} from 'services/cyber-api';
import { StateApi } from 'services/state-api';
import { Toast } from 'utilities/toast';
import { BusinessMap, BusinessMapsApiClient } from 'services/cyber-api';
import moment from 'moment';

@autoinject()
export class Vessel {
    private businessMap: BusinessMap;
    private spinner: Spinner;
    private stats: LatestVesselRisk;
    private siteStats: SiteStats;
    private threatHistoryChart: ThreatsHistory;
    private targetStats: ActionTarget[];
    private DetectionTypes: typeof DetectionTypes = DetectionTypes;

    constructor(
        private businessMapApi: BusinessMapsApiClient,
        private state: StateApi,
        private statsApi: StatsApiClient,
        private threatsApi: ThreatsApiClient,
        private router: Router
    ) {
    }

    private async activate(params: any, routeConfig: RouteConfig, navigationInstruction: NavigationInstruction): Promise<void> {
        this.businessMapApi.getBySiteId(Number(params.id), this.state.company())
            .then(async (businessMap) => {
                this.businessMap = businessMap;

                if (this.businessMap)
                    routeConfig.navModel.setTitle(this.businessMap.name);

                // Trigger a refresh on the threats history chart as we now have the BusinessMap available
                if (this.threatHistoryChart)
                    this.threatHistoryChart.refresh();

                Spinner.hide(this.spinner);

                // Requests in parallel
                await Promise.all([
                    this.statsApi.latestVesselRisk(businessMap.ttSiteId, this.state.company())
                        .then((stats) => this.stats = stats)
                        .catch((error) => {
                            Toast.statsApiError();
                            throw error;
                        }),

                    this.statsApi.sites(this.state.company(), businessMap.ttSiteId)
                        .then((siteStats) => {
                            if (siteStats.length > 1)
                                throw new Error('Multiple site stats found for the same ttSiteId');

                            this.siteStats = siteStats.length > 0 ? siteStats[0] : null;
                        }),

                    this.statsApi.actionTargets(this.state.company(), this.businessMap.ttSiteId.toString(), 3)
                        .then((actionTargets) => this.targetStats = actionTargets)
                        .catch((error) => {
                            Toast.statsApiError();
                            throw error;
                        })
                ]);
            });
    }

    /**
     * Provides a search query based on the action target. Will prepend the target's type if it is known to allow for a
     * more thorough search.
     * @param targetStats The target stats to generate a search query for.
     */
    private getThreatSearchQueryForTarget(targetStats: ActionTarget): string {
        switch (targetStats.type?.toString()) {
            case DetectionTypes.Domain:
                return `domain:${targetStats.target}`;
            case DetectionTypes.IpAddress:
                return `ip:${targetStats.target}`;
            case DetectionTypes.MacAddress:
                return `mac:${targetStats.target}`;
            case DetectionTypes.Unspecified:
            default:
                return targetStats.target;
        }
    }
}
