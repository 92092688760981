import { autoinject } from 'aurelia-framework';
import { Spinner } from 'components/spinner/spinner';
import { saveAs } from 'file-saver';
import {
    ReportsApiClient,
    StatsApiClient,
    LatestVesselRisk, SiteStats, ILatestVesselRisk, ISiteStats, StatusCount, TypeCount
} from 'services/cyber-api';
import { StateApi } from 'services/state-api';
import { Toast } from 'utilities/toast';
import { Utilities } from 'utilities/utilities';
import { Router } from 'aurelia-router';
import moment from 'moment/moment';

@autoinject()
export class Vessels {
    private selectedView: string = 'grid';
    private selectedVessel: LatestVesselRisk;
    private spinner: Spinner;
    private vesselInfo: IVesselInfo[];
    private exporting: boolean = false;

    /*
     * Filter & Search
     */
    private filteredVesselStats: IVesselInfo[];
    private searchText: string;
    private riskFilter: string;
    private trendDirectionFilter: string;

    constructor(
        private state: StateApi,
        private statsApi: StatsApiClient,
        private reportsApi: ReportsApiClient,
        private router: Router,
    ) {
    }

    private async activate(params: any): Promise<void> {
        this.searchText = params.q;
        this.riskFilter = params.risk;
        this.trendDirectionFilter = params.trend;

        this.initialize()
            .then(() => this.search())
            .then(() => Spinner.hide(this.spinner));
    }

    private async initialize(): Promise<void> {
        try {
            const [latestVesselRisk, siteStats] = await Promise.all([
                this.statsApi.latestVesselsRisk(this.state.company()),
                this.statsApi.sites(this.state.company())
            ]);

            this.vesselInfo = latestVesselRisk.map((vesselRisk) => {
                const siteStatsItem = siteStats.find((site) => site.siteId === vesselRisk.siteId);
                return {
                    ...vesselRisk,
                    ...siteStatsItem
                };
            });
        } catch (error) {
            Toast.statsApiError();
            throw error;
        }

        // Sort the stats by risk descending (highest value on top)
        this.vesselInfo.sort((a, b) => {
            // When the first object to compare has no stats, the second should be sorted as first
            if (!a.currentRisk) return 1;
            // When the second object to compare has no stats, the first should be sorted as first
            if (!b.currentRisk) return -1;
            // This effectively sorts the one with the highest value as first
            return b.currentRisk - a.currentRisk;
        });

        // Clone a copy, to filter on
        this.filteredVesselStats = this.vesselInfo;
    }

    private search(): void {
        // Reset the filtered view
        let filteredVesselStats = this.vesselInfo;

        // Check if each of the filters are active and apply them

        if (this.searchText)
            filteredVesselStats = filteredVesselStats
                .filter((v) =>
                    v.name.toLowerCase().includes(this.searchText.toLowerCase())
                    || v.siteId.toString().includes(this.searchText)
                );

        if (this.riskFilter) {
            const riskRange = Utilities.getRiskCategoryRange(this.riskFilter);
            filteredVesselStats = filteredVesselStats
                .filter((v) =>
                    v.currentRisk >= riskRange[0]
                    && v.currentRisk <= riskRange[1]
                );
        }

        if (this.trendDirectionFilter === 'increasing')
            filteredVesselStats = filteredVesselStats
                .filter((v) =>
                    v.currentRisk > v.previousRisk
                );
        if (this.trendDirectionFilter === 'decreasing')
            filteredVesselStats = filteredVesselStats
                .filter((v) =>
                    v.currentRisk <= v.previousRisk
                );

        // Apply the filters to the displayed filtered stats
        this.filteredVesselStats = filteredVesselStats;
    }

    private clearFilters(): void {
        this.searchText = null;
        this.riskFilter = null;
        this.trendDirectionFilter = null;
        this.search();
    }

    private showGridView(): void {
        this.selectedView = 'grid';
    }

    private showListView(): void {
        this.selectedView = 'list';
    }

    private filterOnRiskLevel(risk: number): void {
        const riskCategory = Utilities.getRiskCategory(risk);
        this.riskFilter = riskCategory.toLowerCase();
        this.search();
    }

    private vesselClicked(element: HTMLElement, vesselStats: LatestVesselRisk): void {
        // When table row is clicked, check if the user clicked on the ari badge. This should trigger a filter on that
        // ari category. Otherwise, select the vessel of the row.
        if (element.classList.contains('ari-badge') || element.classList.contains('badge')) {
            this.filterOnRiskLevel(vesselStats.currentRisk);
            return;
        }

        this.router.navigateToRoute('vessel', { id: vesselStats.siteId });
    }

    private async export(): Promise<void> {
        this.exporting = true;

        try {
            const response = await this.reportsApi.exportVessels(this.state.company());
            saveAs(response.data, 'Marlink CyberGuard - Vessels.xlsx');
        } catch (error) {
            throw error;
        } finally {
            this.exporting = false;
        }
    }
}

interface IVesselInfo extends ILatestVesselRisk, ISiteStats {
}
